/* eslint-disable no-mixed-spaces-and-tabs */
import { IonAlert, IonButton, IonIcon, IonInput, IonItem } from '@ionic/react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError /*, NormalText, SmallText*/, NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import Modal from '../../components/modal';
// import plateImage from '../../assets/images/icons/eat.png'
import Basket from '../../lib/basket';
// import { close } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, goBack, isDefined, validateForm } from '../../lib/utils';
import { buyReward, checkDiscount, getProfile, getRewards, sendVoucherCode, showToast } from '../../store/actions';
import './index.css';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { chevronForward, lockClosed } from 'ionicons/icons';
import { QRCodeCanvas } from 'qrcode.react';

class Rewards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReward: null,
      previewRewardModalOpen: false,
      detailsRewardModalOpen: false,
      buyRewardModalOpen: false
    };

  }
  buyReward = (rewardId) => {
    this.props.dispatch(buyReward({ rewardId, fromOrder: this.props?.location?.state?.fromOrder ? true : false }))
  }
  componentDidMount() {
    this.props.dispatch(getProfile())

    this.props.dispatch(getRewards())
  }
  render() {
    const { __, rewards, available_balance } = this.props;
    const { selectedReward, previewRewardModalOpen } = this.state
    return (
      <Loading>
        <Layout color='transparent' headerWithTitle={true} title={__('Redeem TOM bucks')}>
          <div className='absolute-content'>
            <Title className='web-only default-padding'>{__('Redeem TOM bucks')}</Title>
            {rewards?.map(reward => (
              <>
                <div className='unlock-reward-wrapper' style={{ width: '100%' }}>
                  <div>
                    <img src={reward.restaurant.image} />
                    <Subtitle>{__(reward.restaurant.name)}</Subtitle>
                  </div>
                  <div className='box-wrapper'>
                    {reward.available_rewards.map(el => (
                      <div onClick={() => this.setState({ previewRewardModalOpen: true, selectedReward: { ...el, image: reward.restaurant.image } })} style={{ cursor: 'pointer', display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                        <div >
                          <StrongText className='block'>{el.name}</StrongText>
                          <SmallText>{`${__('Redeem with')} ${el.points_required} ${__('TOM bucks')}`}</SmallText>
                        </div>
                        <IonIcon style={{ height: '20px', width: '20px' }} icon={chevronForward} />
                      </div>
                    ))}


                  </div>
                </div>

                <Spacer size={1} />
              </>
            ))}
          </div>
          <Modal
            className="voucher-modal"
            isOpen={previewRewardModalOpen}
            onDidDismiss={() => {
              this.setState({ selectedReward: null, previewRewardModalOpen: false });
            }}
          >
            {selectedReward &&
              <div >
                <Spacer size={1} />

                <Title>{__('Redemption details')}</Title>
                <img style={{ width: '100%' }} className='block' src={selectedReward?.image} />
                <div style={{ padding: 'var(--okx-padding)' }}>

                  <Subtitle className='bold'>{selectedReward.name}</Subtitle>
                  <StrongText className='block'>{`${__(selectedReward.points_required)} ${__('TOM bucks')}`}</StrongText>
                  <Spacer />
                  {selectedReward?.description ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedReward.description }}
                    ></div>
                  ) : null}
                  <Spacer size={1} />

                  <IonButton disabled={available_balance < selectedReward.points_required} onClick={() => this.setState({ buyRewardModalOpen: true })} style={{ margin: 0 }} expand='block' color='primary'>
                    {available_balance < selectedReward.points_required && <IonIcon style={{ height: '15px', width: '15px', marginRight: '5px' }} icon={lockClosed} />}

                    {`${__('Redeem for')} ${selectedReward.points_required} ${__('TOM bucks')}`}
                  </IonButton>
                  <Spacer size={1} />
                  <NormalText className='block centered'>{__('You have')}{' '}{available_balance}{' '}{__('TOM bucks')}</NormalText>
                </div>
              </div>

            }
          </Modal>
          <IonAlert
            isOpen={this.state.buyRewardModalOpen}
            onDidDismiss={() => this.setState({ buyRewardModalOpen: false })}
            header={__('Are you sure?')}
            message={`${__('Are you sure you wish to redeem')} ${selectedReward?.points_required} ${__('TOM Bucks in exchange for the following voucher:')} ${selectedReward?.name}`}
            buttons={[
              {
                text: __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: __('Redeem'),
                handler: () => this.setState({ previewRewardModalOpen:false,buyRewardModalOpen: false,selectedReward:null }, () => { this.buyReward(selectedReward?.id) }),
              },
            ]}
          />
        </Layout>
      </Loading>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    rewards: store.restaurants.rewards || null,
    qr_code: store.profile.profile.qr_code,
    available_balance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
  };
};

export default connect(mapStateToProps)(withTranslation(Rewards));
